  .orderedData-list {
    list-style: none;
    padding: 0;   
  }
  
  .orderedData-list li {
    margin-bottom: 0.5rem;
  }
  
  .orderedData-list-item {
    display: flex;
    justify-content: space-between;
  }
  
  .orderedData-list-item label {
    vertical-align: text-bottom;
    margin-left: 0.2rem;
  }

  .containerUl {
    list-style: none;
    padding: 0; 
  }

  .dropDown {
    width: 100%;
    font-family: 'FilsonProRegular', 'Poppins', 'Roboto', Arial, Helvetica, sans-serif;
    letter-spacing: 0.03rem;
    padding: 3px !important;
    font-size: 13px;
    border-radius: 0px !important;
    cursor: pointer;
  }
  
  h4{
    margin: 20px 0 5px 0;
  }

  .listItems{
    margin-bottom: 14px;
  }

  .returnsHeader{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 60px;
    border-bottom:1px solid #c1c1c1;
    align-items: center;
    position:sticky;
    top:0;
    background: #fff !important;
  }

  .returnsHeader img{
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .refundModalTable {
    border: 1px solid #000000;
    padding: 10px 0;
    font-size: 0.9em;
    height: 80px;
    overflow-y: auto;
    padding-top: 0;
  }

  .refundModalTable .refundTable thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f1f1f1;
  }

  .refundTable .refundTr th {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .refundTable .refundTr div,
  .refundTable tbody tr td {
    font-size: .8em;
  }
