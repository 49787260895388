  .orderedData-list {
    list-style: none;
    padding: 0;   
  }
  
  .orderedData-list li {
    margin-bottom: 0.5rem;
  }

  .orderedData-list-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
  }
  
  .orderedData-list-item label {
    vertical-align: text-bottom;
    margin-left: 0.2rem;
  }

  .containerUl {
    list-style: none;
    padding: 0; 
  }

  .dropDown {
    width: 100%;
    font-family: 'FilsonProRegular', 'Poppins', 'Roboto', Arial, Helvetica, sans-serif;
    letter-spacing: 0.03rem;
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
  }

  h4{
    margin: 20px 0 5px 0;
  }

  .listItems{
    margin-bottom: 14px;
  }

  .returnsHeader{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 60px;
    border-bottom:1px solid #c1c1c1;
    align-items: center;
    position:sticky;
    top:0;
    background: #fff !important;
  }

  .returnsHeader img{
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .listItems div span {
    font-size: 12px;
  }

  .first-column {
    flex: 1;
    display: flex;
    align-items: center;
    text-align: left;
  }

  .column {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 25%;
  }

  .row {
    display: flex;
    width: 100%;
  }

  .fixed-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
  }

  .containerUl li {
    list-style: none;
    padding: 0;
    overflow-x: auto;
  }