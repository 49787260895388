.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 63px;
  margin-left: 50px;
  margin-right: 50px;
}

.title-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.download-btn {
  width: 220px;
  height: 40px;
}
.upload-btn {
  width: 220px;
  height: 40px;
  margin-bottom: 10px;
}

.upload-section {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.upload-section p {
  color: #a5a5a5;
}

.visualy-hidden-input {
  clip: 'rect(0 0 0 0)';
  clip-path: 'inset(50%)';
  height: 1;
  overflow: 'hidden';
  position: 'absolute';
  bottom: 0;
  left: 0;
  white-space: 'nowrap';
  width: 1;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

