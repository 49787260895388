.OrderDetailsPage {
  font-size: 0.9em;
  padding: 20px;
}

.OrderDetailsPage .OrderNumber {
  margin: 10px 0 10px 0;
  /* font-size: 1.2em; */
}

.Section {
  overflow: hidden;
  padding-bottom:15px;
}

.Status{
  margin-left:210px;
}

.refundButton {
  margin: 5px 0;
  height: 45px;
  width: 200px;
}

.returnButton,
.installerRefundButton {
  margin: 5px 0;
  height: 45px;
  width: 200px;
}

.tabContainer {
  width: 100%;
  display: inline-block;
}

.rrContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
}

.containerButtons {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.bookingStatus {
  height: 25%;
  width: 45%;
  margin-bottom: 10%;
}

.bookingStatusAndActions {
  float: left;
  margin-left: 5%;
}
