.BookingDetailsPage {
  font-size: 0.9em;
  /*padding: 20px;*/
}

.BookingDetailsPage .OrderNumber {
  margin: 10px 10px 10px 19px;
  font-size: 1.2em;
}

.Flex-container {
  display: flex;
}
.Flex-container > span {
  width: 25%;
}

.Section {
  overflow: hidden;
  padding-bottom: 15px;
}

.Status {

  margin: 18.5%;
}

.Status2 {
  margin: 1%;
}

.BookingField {
  padding-left: 15%;
}

.BookingStatus {
    display: grid;
    grid-template-columns: 25% 25% 25% 25% ;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 4%;
    margin-right:4%;
    
}

.slotType {
  cursor: hand;
  border: 1px;
  border-radius: 4px;
  border-color: "black";
  border-style: "solid";
  font-weight: "bold";

}