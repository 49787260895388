.listItems{
    margin-top: 20px;
}

h4, p{
    margin: 0px !important;
}

.subHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    font-size: 12px !important;
}

.sectionlayout{
    width: 18%;
    display: inline-block;
    text-align: center;
    font-size: 12px;
}