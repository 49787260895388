.FindOrder .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FindOrder .SearchSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.FindOrder .input {
  width: 40%;
  display: flex;
}

.FindOrder .container h1 {
  margin-left: 0;
  margin-bottom: 10;
}

.FindOrder input,
.FindOrder select {
  width: 50%;
  margin: 10px;
  height: 25px;
  font-size: 1em;
}

.FindOrder select {
  height: 31px;
}

.FindOrder .search button {
  margin-top: 15px;
}
.FindOrder div.react-datepicker__input-container input{
  width: 60% !important;
  position:relative;
  left:45px;
}
.FindOrder button.react-datepicker__close-icon::after {
  background-color: #0071BC !important;
}
.FindOrder button.react-datepicker__close-icon:hover {
  background-color: transparent !important;
}
.FindOrderLoader{
  top:300px !important;
  left:700px !important;
}
