/* TODO Below fonts were copied from the old OMS system. Need to refactor*/

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'FilsonProBold';
  src: url('./fonts/FilsonPro/FilsonProBold-webfont.woff2') format('woff2'),
  url('./fonts/FilsonPro/FilsonProBold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FilsonProRegular';
  src: url('./fonts/FilsonPro/FilsonProReg-webfont.woff2') format('woff2'),
  url('./fonts/FilsonPro/FilsonProReg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FilsonProRegular';
  src: url('./fonts/FilsonPro/FilsonProBold-webfont.woff2') format('woff2'),
  url('./fonts/FilsonPro/FilsonProBold-webfont.woff') format('woff');
  font-weight: bold;
}

/* @font-face {
  font-family: 'FontAwesome';
  src: url('../node_modules/font-awesome/css/font-awesome.min.css');
} */

body {
  margin: 0;
  padding: 0;
  font-family: 'FilsonProRegular', 'Poppins', 'Roboto', Arial, Helvetica, sans-serif;
  letter-spacing: 0.03rem;
  box-sizing: border-box;
}

#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.clearfix::after {
  display: block;
  content: " ";
  clear: both;
}
