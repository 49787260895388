.FilterField .filter-field-content {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

.FilterField .filter-field-content .filter-field-options {
  display: flex;
  align-items: center;
  width: 100%;
  /* padding-left: 10px; */
}

.FilterField .filter-field-content .filter-field-options label {
  width: 100%;
  cursor: pointer;
  padding-left: 10px;
  word-break: break-all;
  font-size: 14px;
}

.FilterField .filter-field-heading.bottom-border {
  border-bottom: 1px solid #000;
}

.FilterField .filter-field-heading {
  cursor: pointer;
}
