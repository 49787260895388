.BookingActionsSection {
 float: left;
}

.BookingActionsSection .label {
  text-align: center;
}
.BookingActionsSection .ActionButton {
    width: 100%;
    max-width: 200px;
    margin: 10px 0px;
    height: 50px;
    padding: 10px 24px !important;
    font-size: 0.95em;
}

.BookingActionsSection .ActionsRow {
  display: grid;
}
