.ActionBar {
  display: flex;
  margin-right: 100px;
}

.ActionBar .action-items {
  display: flex;
  height: 50%;
  align-self: center;
  margin-left: 5em;
  flex: 1;
  justify-content: right;
}

.ActionBar .search-filter {
  display: flex;
  align-items: center;
}

.ActionBar .search-filter .filter-button {
  font-size: 1.75em;
  border: 1px solid #000000;
  margin-left: 10px;
  padding: 0 0.1em;
  height: 29.5px;
  cursor: pointer;
}

.ActionBar .search-filter .search-button {
  height: 31px;
  background: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  border-left: none;
  line-height: 10px;
  width: 50px;
}

.ActionBar .search-filter input {
  height: 25px;
  font-size: 1em;
  padding-left: 0.5em;
  border-right: none;
}

.ActionBar .FilterBox {
  position: absolute;
  background: #fff;
  width: 100%;
  max-width: 350px;
  top: 70px;
}
