.BookingDetailsSection {
  /* margin-top: 5px; */
  float:left;
  width: 850px; 
}
.Content{
   width: 850px;
}
.SectionContent{  
   width: 850px; 
  border: 1px solid #0059A1;
 /*  padding: 10px 20px;  */
  margin: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
