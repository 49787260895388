.ActionsSection{
 float:right;
}


.ActionsSection .ActionButton {
  width: 100%;
  max-width: 200px;
  margin: 10px 0px;
  height: 50px;
  padding: 10px 24px !important;
  font-size: 0.95em;
}
.ActionsRow{
  display: grid;
}

.componentLevelStyles{
  height: 45px !important;
  margin: 5px 0 !important;
}
