.FilterBox {
  z-index: 10;
  padding: 20px;
  border: 1px solid #000;
}

.FilterBox .top-bar {
  font-size: 1.2em;
  position: relative;
}

.FilterBox .top-bar .close-button {
  font-size: 50px;
  position: absolute;
  cursor: pointer;
  top: -23px;
  right: 0;
}

.FilterBox .top-bar .close-button:after {
  content: "\00d7";
}

.FilterBox .content-box ul {
  list-style: none;
  padding: 0;
}

.FilterBox .content-box ul li .filter-field-heading {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  padding: 10px;
}

.FilterBox .content-box ul li:last-child .filter-field-heading {
  border-bottom: 1px solid #000;
}

.FilterBox .content-box ul li .filter-field-heading .fa-angle-down {
  margin-right: 10px;
  font-size: 1.2em;
}

.FilterBox .action-bar {
  display: flex;
  justify-content: space-around;
}

.FilterBox .action-bar .cancel-button {
  background: #fff;
  color: #555;
  border: 1px solid #888;
}
