.InstallerOptions .container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.InstallerOptions .SearchSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.InstallerOptions .input {
  width: 40%;
  display: flex;
}

.InstallerOptions .container h1 {
  margin-left: 0;
  margin-bottom: 10;
}

.InstallerOptions input,
.InstallerOptions select {
  width: 50%;
  margin: 10px;
  height: 25px;
  font-size: 1em;
}

.InstallerOptions select {
  height: 31px;
}

.InstallerOptions .search button {
  margin-top: 15px;
}
.InstallerOptions div.react-datepicker__input-container input{
   width: 60% !important;
   position:relative;
   left:45px;
}
.InstallerOptions button.react-datepicker__close-icon::after {
  background-color: #0071BC !important;
}
.InstallerOptions button.react-datepicker__close-icon:hover {
  background-color: transparent !important;
}
.InstallerOptionsLoader{
top:300px !important;
left:700px !important;
}