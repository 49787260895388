.ActionsSection {
 display: inline-block;
}

.label {
  text-align: center;
}
.ActionsSection .ActionButton {
  width: 100%;
  top : 1%;
  height: 50px;
  font-size: 0.95em;
}
.ActionsRow {
  display: grid;
}
