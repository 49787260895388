.Navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #0071BC;
  height:68px;
  display: flex;
  flex-direction: row;
}

.Navbar .logo {
  background-color: #ffffff;
  margin-top: 8px;
  width: 14%;
  padding-left: 10px;
  height:50px
}

.Navbar button{
  display: inline-block;
  text-decoration: none;
  margin-left:30px;
  color:#fff !important;
  /* position: absolute;
  top: 30%; */
  font-weight: bold;
}
.menus{
  position:fixed;
  margin-top: 1.5em;
}

.logout{
  position: absolute !important;
  right: 0px !important;
  margin-right: 30px !important;
  top: 26% !important;
}

.downArrow{
  vertical-align: middle;
  position: absolute;
  right: -12px;
}