.header {
  padding: 0px 0px 10px 15px;
  text-decoration: underline;
}

.tableCheck {
  padding-right: 5px;
  font-weight: 300;
  font-size: 0.9em;
}

.headerStyle {
  font-weight: bold;
}

.tableStyle {
  width: 100%;
  border-collapse: collapse;
}

.collapsible {
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.buttonClass {
  background-color: white;
  color: black;
}