.App {
  display: flex;
  min-height: 100vh;
}

.App .Page {
  flex: 1;
  padding-top: 60px;
}

.App .Page h1 {
  font-size: 1.8em;
  margin-left: 50px;
}

/* TODO Below basic styling was copied from the old OMS system. Need to refactor*/

button {
  cursor: pointer;
}

button,
button[disabled]:hover {
  font-family: "FilsonProBold", "Montserrat", Arial, Helvetica, sans-serif;
  /* font-size: 0.95rem; */
  font-weight: bold;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  background: #0071BC;
  outline: none;
}

button[disabled],
button[disabled]:hover,
button[disabled]:active {
  cursor: default;
  background: #666666;
  color: #cccccc;
}

button:hover {
  background: #0059A1;
}

input[disabled] {
  color: #888888;
}

a {
  font-weight: bold;
  color: black;
  text-decoration: underline;
}

a:visited,
a:active {
  color: black;
}

a button {
  text-decoration: none;
}

textarea {
  resize: none;
  min-height: 50px;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
textarea,
select {
  outline: none;
  padding: 2px;
  border: 1px solid #000000;
  border-radius: 0px;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"] {
  -webkit-appearance: searchfield;
}

input[type="text"]::-webkit-search-cancel-button,
input[type="email"]::-webkit-search-cancel-button,
input[type="number"]::-webkit-search-cancel-button,
input[type="password"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

input[type="radio"] + span:before,
input[type="checkbox"] + span:before {
  font-family: "FontAwesome", "FilsonProRegular", Arial, Helvetica, sans-serif;
  padding-right: 3px;
  font-size: 1.5rem;
  user-select: none;
}

input[type="radio"] + span:before {
  content: "\f10c"; /* circle-blank */
}

input[type="radio"]:checked + span:before {
  content: "\f111"; /* circle */
}

input[type="checkbox"] + span:before {
  content: "\f096"; /* check-empty */
  vertical-align: middle;
  margin-right: 0.5rem;
  user-select: none;
}

input[type="checkbox"]:checked + span:before {
  content: "\f14a"; /* check */
  margin-right: 0.4rem;
}

select option {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.toast-success{
  background-color: lightgreen;
  color:black
}
.toast-error{
  background-color: rgb(226, 153, 153);
  color:black
}
.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #0071BC;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 1s linear infinite;
  position:fixed;
  left:50%;
  top:45%;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}