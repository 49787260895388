.LegalParentListPage .CustomList {
    margin: 0 50px 50px 50px;
  }

  .headerContainer{
    display: flex;
    justify-content: space-between;
    margin-right: 100px;
    cursor: pointer;
    width: 100%;
    align-items: center;
  }

  .errroStyles{
    color: red;
    text-align: left;
    font-size: 12px;
  }
  
  .newButton{
  width: 220px;
  height: 40px;
  margin-right: 45px !important;
}

  