.ModalBox{
    top: '50%';
    left: '50%';
        right: 'auto';
        bottom: 'auto';
        width: '30%';
        margin-right: '-50%';
        transform: 'translate(-50%; -50%)';
}

.action{
float: right;
margin-top:10px;
}
.error{
    color:red;
    font-size:12px;
}
.labelStyle{
    position: absolute;
}