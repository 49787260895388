.TabularSection {
  /* margin-top: 30px ; */
}

.TabularSection .TabHeaders {
  display: flex;
}

.TabularSection .TabHeaders .Container {
  width: 168px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0059A1;
  cursor: pointer;
}

.TabularSection .TabHeaders .Container.active {
  background-color: #0059A1;
  color: #ffffff;
}

.TabularSection .TabContent {
  border: 1px solid #0059A1;
  padding: 10px 20px;
}

.rmaButton{
  vertical-align: top;
  margin: 32px 0 0 -3px;
  border-radius: 5px;
}
