.OrdersPage .CustomList {
  margin: 0 10px 10px 10px;
}

.ActionBar {
  position: relative;
}

.insuranceDetails{
  margin-left: 1%;
}

.insuranceDetails p{
  margin: 0px 0px 5px 0px !important;
  font-weight: bold;
  max-width: 45%;
  font-size: 12px;
  display: inline-block;
}

.insuranceDetails p span{
  font-weight: normal;
  margin-left: 5px;
  float: right;
}

.editButton{
  color: #fff;
  width: 10%;
  border-radius: 5px;
  margin: 20px 0 0 0px;
  font-weight: normal;  
  display: block;
  border: 1px solid #0059A1;
}
.cancle{
  color: #000;
  background-color: #fff;
  margin: 20px 0 0 10px;
  border-radius: 5px;
  width: 10%;
  border-radius: 5px;
  border: 1px solid #000;
}

.insuranceDetails div .dateStyles{
  vertical-align: middle;
  margin: 0 0 0 5px;
  float: right;
}

.react-datepicker-wrapper{
  width: 40%;
}

.calenderStyles{
  position: relative;
    top: -5px;
    right: 20px;
}

.calenderStylesDealers{
  top: -27px;
  left: 95.6%;
  display: inline;
  margin: 0;
  position: relative;
  padding: 0;
}

.checkstyles{
  cursor: pointer;
}

.legalContainer{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.submitSection {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
}

.formFields {
  display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 15px;
}

.formFields label {
  margin-bottom: 10px;
}

.formFields input {
  float: right;
}

.innerContainer{
    width:100%;
    display: flex;
    flex-direction: column;
    margin-right: 5%;
}

h3{
  text-decoration: underline;
}
 h4{
   display: inline;
 }
 .legalParentSection{
  margin:0;
  font-weight:bold;
 }

 .legalParentSection span{
   padding: 5px;
   /* border: 2px solid #dbdbdb; */
   margin-left: 2px;
   border-radius: 8px;
   color: #0059A1;
   font-weight: bold;
 }

 .groupDetails{
  color: #0059A1;
  font-size: 16px;
  font-weight: bold;
 }

 .pagination {
  margin-top: 20px;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.pagination .pagination-inner .page {
  padding: 0 10px;
  margin: 0 1px;
  cursor: pointer;
}

.pagination .pagination-inner .page.current {
  font-weight: bold;
}