 
  
  body {
    background: #fff;

  }

  .container {
    width: 95%;
    display: flex !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  
  .bloc-tabs {
    top: 100px;
    display: flex;
    left: 43px;
    padding-bottom: 0;

  }

  .filler {
    padding-top: 50px;

  }


  .retailertabs {
    top : 100px;
    left: 43px;
    padding: 15px;
    text-align: center;
    width: 150px;
    color: black; 
    background-color: white;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
 

  }
  .retailertabs:hover{
    top : 100px;
    color: white;
  }
  .retailertabs:not(:last-child){
    background-color: default;
    border: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .active-tabs  {
    color: #FFFFFF;
    left: 43px;
    top : 100px;
    padding: 15px;
    text-align: center;
    width: 25%;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
  }
  
  
 
  .content-tabs {
    width: 100%;
  }
  .content1 {
    background: white;
    padding: 5px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .content1 h2 {
    padding: 0px 0 5px 0px;
  }
  .content1 hr {
    width: 100%;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .content1 p {
    width: 100%;
    height: 100%;
  }
  .active-content {
    display: block;
  }




.top-m-1_5 {
    margin-top: 1.5em;
}

.con-center {
    justify-content: center !important;
}

.file-msg {
    font-size: x-small;
    font-weight: bold;
}

.logo-img {
    width: max-content;
}



.image-area {
    position: relative;
}

.image-area img {
    max-width: 130px;
    height: auto;
}

RetailerUploadPage .OrderNumber {
    margin: 10px 0 10px 0;
    font-size: 1.2em;
    display: inline;
    width: 100%
}


.makePaymentButton{
  margin-left: 31px;
  margin-top: 15px;
}