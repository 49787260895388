.CustomList {
  overflow-x: auto;
  overflow-y: auto;
  font-size: 0.8em;
}

.CustomList table {
  border-collapse: collapse;
  width: 100%;
}

.CustomList table thead th div {
  display: flex;
  justify-content: space-between;
}

.CustomList table thead th div,
.CustomList table tbody td {
  margin-right: 0px;
  padding-right: 7px;
}

.CustomList table thead th,
.CustomList table tbody td {
  border-bottom: 1px solid #ddd;
}

.CustomList table tr {
  height: 35px;
}

.CustomList table tbody tr:hover {
  background-color: rgb(231, 231, 231);
}

.CustomList table thead th span {
  position: absolute;
  right: 50px;
  font-size: 1.5em;
}

.CustomList .pagination {
  margin-top: 20px;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
}

.CustomList .pagination .pagination-inner .page {
  padding: 0 10px;
  margin: 0 1px;
  cursor: pointer;
}

.CustomList .pagination .pagination-inner .page.current {
  font-weight: bold;
}
.Clickable{
  cursor: pointer;
}